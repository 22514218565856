body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Overrides to make datepicker match theme */
.react-datepicker.datepicker-calendar .react-datepicker__header {
	background-color: #f9fafb;
}

.react-datepicker.datepicker-calendar .react-datepicker__day--selected,
.react-datepicker.datepicker-calendar .react-datepicker__day--keyboard-selected,
.react-datepicker.datepicker-calendar .react-datepicker__month-text--keyboard-selected,
.react-datepicker.datepicker-calendar .react-datepicker__quarter-text--keyboard-selected,
.react-datepicker.datepicker-calendar .react-datepicker__year-text--keyboard-selected {
	background-color: #4338ca;
}

/* Fixes an issue with the popover arrow being out of position */
.react-datepicker-wrapper {
	width: 320px !important;
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
	border: none !important;
}

.react-datepicker-wrapper {
	width: 200px !important;
}
